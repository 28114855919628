import userServices from '../../../../services/user'
import roleServices from '../../../../services/role'

export default {
    data() {
        return {
            item: {
                organization_id: null,
                id: null,
                first_name: null,
                last_name: null,
                email: null,
                title: null,
                phone_number: null,
                location: null,
                role_id: null
            },
            formType: 'INSERT',
            roleMetas: [],
            organizations: []
        }
    },
    computed: {
        form() {
            if(this.formType == 'INSERT'){
                return {
                    title: 'New User',
                    isDisable: false
                }
            } 
            if(this.formType == 'UPDATE'){
                return {
                    title: 'Update User',
                    isDisable: true
                }
            }
        }
    },
    watch: {
        'item.organization_id'(val){
            if (this.formType == 'INSERT') {
                this.getRoleMeta(val)
                this.item.role_id = null
            }
        }
    },
    methods: {
        forceRerenderInsert(organization_id) {
            this.formType = 'INSERT'      
            // if (this._isNotisphere ) {
            //     this.item.organization_id = organization_id == null ? this.$store.state.orgId : organization_id
            //     this.fetchOrgMeta()
            // } else {
            //     this.item.organization_id = this.$store.state.orgId
            // }            
            this.$refs.modal.show()
        },
        forceRerenderUpdate(id, organization_id) {
            this.formType = 'UPDATE'     
            this.getRoleMeta(organization_id)
            this.getUser(id)
            this.$refs.modal.show()
        },
        async save() {
            var isValid = await this.$refs['observer'].validate()
            if(isValid && this.formType == 'INSERT') {
                this.createUser()
            }
            if(isValid && this.formType == 'UPDATE'){
                this.updateUser()
            }
        },
        updateUser() {
            userServices.updateUser(this.item).then(resp=>{
                if(!resp.error){   
                    this.$emit('onCompleted', 'UPDATE')
                    this.$refs.modal.hide()
                }
            })
        },
        createUser() { 
            userServices.createUser(this.item).then(resp=>{
                if(!resp.error){   
                    this.$emit('onCompleted', 'INSERT')
                    this.$refs.modal.hide()
                }
            })
        },
        getUser(id) {
            userServices.getUser(id).then(resp=>{
                if(!resp.error){
                    this.item = resp.data.d         
                }
            })
        },
        getRoleMeta(organization_id) {
            roleServices.getMeta(organization_id).then(resp=>{
                if(!resp.error){                    
                    if (this.formType == 'INSERT') {
                        this.roleMetas = resp.data.d.filter(x => x.allowed_creation == true)
                    }
                    if (this.formType == 'UPDATE') {
                        this.roleMetas = resp.data.d   
                    }           
                }
            })
        },
        // fetchOrgMeta() {
        //     orgServices.findMeta().then(resp=>{
        //         if(!resp.error){
        //             this.organizations = resp.data.d          
        //         }
        //     })
        // }
    }
}