var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('noti-table',{ref:"table",attrs:{"tableName":"Users","showAddMore":"","header":_vm.header,"total":_vm.total,"items":_vm.items,"searchQuery":_vm.searchQuery,"showGlobalSearch":"","showSearchBtn":true,"showSearch":""},on:{"update:searchQuery":function($event){_vm.searchQuery=$event},"update:search-query":function($event){_vm.searchQuery=$event},"requestFetchData":_vm.fetchData,"addMoreClick":_vm.addMoreClick,"rowClick":function($event){return _vm.updateUser($event)}},scopedSlots:_vm._u([{key:"avatar",fn:function(ref){
var item = ref.item;
return [(item.profile_photo && item.id)?_c('b-avatar',{staticClass:"align-baseline mr-2",attrs:{"variant":"primary","src":'data:image;base64,'+item.profile_photo}}):(item.id)?_c('b-avatar',{staticClass:"align-baseline mr-2",attrs:{"variant":_vm._fetchAvatarVariant(item.email),"text":_vm._name2Digit(item.full_name)}}):_vm._e()]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{style:({background: _vm._findObj(_vm.user_status, item.status, 'value', 'color')})},[_vm._v(_vm._s(_vm._findObj(_vm.user_status, item.status)))])]}},{key:"last_logged_in",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"small",staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm._f("toLocal")(item.last_logged_in)))])]}},{key:"action",fn:function(ref){
var item = ref.item;
return [_c('noti-dropdown',[_c('b-dropdown-item',{on:{"click":function($event){return _vm.updateUser(item, $event)}}},[_c('i',{staticClass:"far fa-edit mr-2"}),_vm._v("Edit")]),(item.status == 'PENDING' && item.id != _vm.currentUserId)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.resendVerificationEmail(item.id, $event)}}},[_c('i',{staticClass:"far fa-paper-plane mr-2"}),_vm._v("Resend Verification Email")]):_vm._e(),(item.status != 'PENDING' && item.id != _vm.currentUserId)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.sendResetPasswordEmail(item.id, $event)}}},[_c('i',{staticClass:"fas fa-envelope-open-text mr-2"}),_vm._v("Send Reset Password Email")]):_vm._e(),(item.status == 'VERIFIED' && item.id != _vm.currentUserId)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.updateStatus(item, $event)}}},[_c('i',{staticClass:"fas fa-lock mr-2"}),_vm._v("Deactivate User")]):_vm._e(),(item.status == 'DEACTIVATED' && item.id != _vm.currentUserId)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.updateStatus(item, $event)}}},[_c('i',{staticClass:"fas fa-check-double mr-2"}),_vm._v("Verify User")]):_vm._e(),(item.id != _vm.currentUserId && item.is_admin == false)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteUser(item, $event)}}},[_c('i',{staticClass:"far fa-trash-alt mr-2"}),_vm._v("Delete User")]):_vm._e()],1)]}}])},[_c('UserModal',{key:_vm.userModalKey+'=userModalKey',ref:"userModal",on:{"onCompleted":function($event){return _vm.onCompleted($event)}}}),(true)?_c('template',{slot:"searchBar"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('noti-form-select',{attrs:{"options":_vm.organizations,"trackBy":"id","labelBy":"name","label":"Organization","hideDetails":""},on:{"update":_vm.fetchData},model:{value:(_vm.organization_id),callback:function ($$v) {_vm.organization_id=$$v},expression:"organization_id"}})],1)],1)],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }