import userServices from '../../../services/user'
import UserModal from './UserModal'
import { USER_STATUS } from '../../../misc/commons'

export default {
    components: {
        UserModal
    },
    data() {
        return {
            total: 0,
            items: [],

            searchQuery: {},
            organization_id: null,
            organizations: [],

            user_status: USER_STATUS,
            userModalKey: 0,
        }
    },
    mounted() {
        if (false) {
            this.fetchOrgMeta()
        }
    },
    computed: {
        header() {
            var arr = [
                {
                    label: '',
                    name: 'avatar',
                    sort: false,
                    row_text_alignment: 'text-center',
                    column_text_alignment: 'text-center',
                },
                {
                    label: 'Name',
                    name: 'full_name',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Email',
                    name: 'email',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Organization',
                    name: 'organization_name',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Role',
                    name: 'role_name',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left'
                },
                {
                    label: 'Organization Type',
                    name: 'organization_type',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left'
                },
                {
                    label: 'Status',
                    name: 'status',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    column_classes: 'col-stt'
                },
                {
                    label: 'Last Logged In',
                    name: 'last_logged_in',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    column_classes: 'col-stt'
                },
                {
                    name: 'action',
                    row_text_alignment: 'text-right',
                    column_text_alignment: 'text-right',
                }
            ]

            if (false) {
                return arr
            } else {
                return arr.filter(x => {
                    if (!['organization_name', 'organization_type'].includes(x.name)) {
                        return x
                    }
                })
            }
        },
        currentUserId() {
            return this.$store.state.user.id ? this.$store.state.user.id : -1
        }
    },
    methods: {
        onCompleted(type) {
            if (type == 'UPDATE') {
                this._showToast('Updated successfully!')
            }
            if (type == 'INSERT') {
                this._showToast('Added successfully!')
            }
            this.fetchData()
        },
        addMoreClick() {
            this.userModalKey += 1
            this.$nextTick(() => {
                this.$refs.userModal.forceRerenderInsert(this.organization_id)
            })
        },
        updateUser(payload, e = null) {
            this.userModalKey += 1
            this.$nextTick(() => {
                this.$refs.userModal.forceRerenderUpdate(payload.id, payload.organization_id)
            })
            if (e) {
                e.stopPropagation()
            }
        },
        fetchDelay() {
            this.$refs.table.fetchDelay()
        },
        fetchData() {
            userServices.findUser({
                ...this.searchQuery,
                sortField: this.searchQuery.sort[0] ? this.searchQuery.sort[0].name : null,
                sortDirection: this.searchQuery.sort[0] ? this.searchQuery.sort[0].order : null,
                organizationId: this.organization_id
            }).then(resp => {
                if (!resp.error) {
                    this.items = resp.data.d.c
                    this.total = resp.data.d.t
                }
            })
        },
        // fetchOrgMeta() {
        //     orgServices.findMeta().then(resp => {
        //         if (!resp.error) {
        //             this.organizations = resp.data.d
        //         }
        //     })
        // },
        sendResetPasswordEmail(id, e) {
            userServices.sendResetPasswordEmail(id).then(resp => {
                if (!resp.error) {
                    this._showToast('Email sent successfully!')
                }
            })
            e.stopPropagation()
        },
        resendVerificationEmail(id, e) {
            userServices.resendVerificationEmail(id).then(resp => {
                if (!resp.error) {
                    this._showToast('Email sent successfully!')
                }
            })
            e.stopPropagation()
        },
        updateStatus(item, e) {
            var new_status = null
            switch (item.status) {
                case 'VERIFIED':
                    new_status = 'DEACTIVATED'
                    break
                case 'DEACTIVATED':
                    new_status = 'VERIFIED'
                    break
            }
            if (new_status) {
                userServices.updateStatus({
                    id: item.id,
                    status: new_status
                }).then(resp => {
                    if (!resp.error) {
                        this.onCompleted('UPDATE')
                    }
                })
            }
            e.stopPropagation()
        },
        deleteUser(item, e) {
            this._showConfirm('You are about to delete this user, this cannot be undone.').then(confirm => {
                if (confirm) {
                    userServices.deleteUser(item.id).then(resp => {
                        if (!resp.error) {
                            this.onCompleted('UPDATE')
                        }
                    })
                }
            })
            e.stopPropagation()
        },
    }
}